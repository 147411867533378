import { EventProviderApi } from '@antegma/accallio-cloud-connection-api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'

type TEnv = {
  CONNECTION_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  CONNECTION_API_BASE_PATH: process.env.REACT_APP_CONNECTION_API_BASE_PATH || 'https://connection-api.dev.cloud.accallio.com',
})

const eventProviderApi = new EventProviderApi(undefined, env.CONNECTION_API_BASE_PATH, Api.instance.axiosInstance)

export default eventProviderApi
