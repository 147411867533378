import { EventProviderList } from '@antegma/accallio-cloud-connection-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TEventProvidersState = EventProviderList

const initialState = {
  items: [],
  page: 0,
  pageSize: 0,
  total: 0,
}

/**
 * @param state
 * @param action
 */
export default function eventProvidersReducer(state: TEventProvidersState = initialState, action: AnyAction): TEventProvidersState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
