const actions = {
  SET_STATE: 'tenant-users/SET_STATE',
  LOAD_STATE: 'tenant-users/LOAD_STATE',
  GET_STATE: 'tenant-users/GET_STATE',
  ADD_USER: 'tenant-user/ADD_USER',
  REMOVE_USER: 'tenant-user/DELETE_USER',
  ADD_ADMIN: 'tenant-user/ADD_ADMIN',
  REMOVE_ADMIN: 'tenant-user/REMOVE_ADMIN',
}
export default actions
