/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import Axios from 'axios'
import { notification } from 'antd'
import { EventProvider } from '@antegma/accallio-cloud-connection-api'
import eventProviderApi from '~/services/Connections/eventProviderApi'
import { EEventProviderType } from '~/services/Connections/types'

import actions from './actions'

/**
 *
 */
export function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const response = yield eventProviderApi.getEventProviders(undefined, 1, 50, Axios.defaults).catch((error) => {
    notification.error({
      message: 'EventProviders: cannot load EventProviders',
      description: error.message,
    })
  })

  yield put({
    type: actions.SET_STATE,
    payload: response.data,
  })
}

/**
 *
 */
export function* ADD_EVENT_PROVIDER({ payload }: AnyAction) {
  const { eventProvider } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  switch ((eventProvider as EventProvider).type) {
    case EEventProviderType.adobeCreativeCloudWebhook:
    case EEventProviderType.adobeExperienceManagerAssetsWebhook:
    case EEventProviderType.cantoWebhookEventProvider:
      yield eventProviderApi.createEventProvider(eventProvider, Axios.defaults)
      break

    default:
      // facebook, linkedin, twitter, instagram managed by accallio micro-service.
      break
  }

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* EDIT_EVENT_PROVIDER({ payload }: AnyAction) {
  const { eventProvider } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const eventProviderId = (eventProvider as EventProvider).id as string

  switch ((eventProvider as EventProvider).type) {
    case EEventProviderType.adobeCreativeCloudWebhook:
    case EEventProviderType.adobeExperienceManagerAssetsWebhook:
    case EEventProviderType.cantoWebhookEventProvider:
      yield eventProviderApi.updateEventProvider(eventProviderId, eventProvider, Axios.defaults)
      break
    default:
      break
  }

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* DELETE_EVENT_PROVIDER({ payload }: AnyAction) {
  const { eventProvider } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const eventProviderId = (eventProvider as EventProvider).id as string

  yield eventProviderApi.deleteEventProvider(eventProviderId, Axios.defaults).catch((error) => {
    notification.error({
      message: `EventProviders: Unable to delete EventProvider with ID ${eventProviderId}`,
      description: error.message,
    })
  })
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.DELETE_EVENT_PROVIDER, DELETE_EVENT_PROVIDER),
    takeEvery(actions.ADD_EVENT_PROVIDER, ADD_EVENT_PROVIDER),
    takeEvery(actions.EDIT_EVENT_PROVIDER, EDIT_EVENT_PROVIDER),
  ])
}
