import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Row, PageHeader } from 'antd'
import ProductInstanceTable from '~/components/ProductInstance/ProductInstanceTable'
import { useDispatch, useSelector } from 'react-redux'
import { TSagas } from '~/redux/interface'
import { getProductInstances } from '~/redux/product-instance/actions'
import ProductDetails from './details'


const Products = (): JSX.Element => {
    const dispatch = useDispatch();
    const productInstanceState = useSelector((state: TSagas) => state.productInstances);

    useEffect(() => {
        dispatch(getProductInstances(productInstanceState.page, productInstanceState.pageSize));
    }, [productInstanceState.page, productInstanceState.pageSize])

    return (
        <>
            <Helmet title="Active Products" />
            <PageHeader title="Products">
                <Row gutter={16}>
                    <ProductInstanceTable productInstancesState={productInstanceState} />
                </Row>
            </PageHeader>
        </>
    )
}

export default {
    name: 'Products',
    routeProps: {
        path: '/products',
        component: Products,
    },
    children: [ProductDetails],
    navProps: {
        title: 'Products'
    },
}
