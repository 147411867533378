import { ServiceAccountList } from '@antegma/accallio-cloud-tenant-api'
import { AnyAction } from 'redux'
import actions from './actions'

export type TServiceAccountsState = ServiceAccountList

const initialState = {
  items: [],
  page: 0,
  pageSize: 0,
  total: 0,
}

/**
 * @param state
 * @param action
 */
export default function serviceAccountsReducer(state: TServiceAccountsState = initialState, action: AnyAction): TServiceAccountsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
