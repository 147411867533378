/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'
import Axios from 'axios'
import { ServiceAccountApi, ServiceAccount } from '@antegma/accallio-cloud-tenant-api'

import actions from './actions'

type TEnv = {
  TENANT_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  TENANT_API_BASE_PATH: process.env.REACT_APP_TENANT_API_BASE_PATH || 'https://tenant-api.cloud.accallio.com',
})

const serviceAccountApi = new ServiceAccountApi(undefined, env.TENANT_API_BASE_PATH, Api.instance.axiosInstance)

/**
 *
 */
export function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const response = yield serviceAccountApi.getServiceAccounts(1, 50, Axios.defaults).catch((error) => {
    notification.error({
      message: 'ServiceAccounts: cannot load ServiceAccounts',
      description: error.message,
    })
  })

  yield put({
    type: actions.SET_STATE,
    payload: response.data,
  })
}

/**
 *
 */
export function* ADD_SERVICE_ACCOUNT({ payload }: AnyAction) {
  const { serviceAccount } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  yield serviceAccountApi.createServiceAccount(serviceAccount, Axios.defaults)

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* EDIT_SERVICE_ACCOUNT({ payload }: AnyAction) {
  const { serviceAccount } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const serviceAccountId = (serviceAccount as ServiceAccount).id as string

  yield serviceAccountApi.updateServiceAccount(serviceAccountId, serviceAccount, Axios.defaults)

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* DELETE_SERVICE_ACCOUNT({ payload }: AnyAction) {
  const { serviceAccount } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const serviceAccountId = (serviceAccount as ServiceAccount).id as string
  yield serviceAccountApi.removeServiceAccount(serviceAccountId, Axios.defaults).catch((error) => {
    notification.error({
      message: `ServiceAccounts: Unable to delete ServiceAccount with ID ${serviceAccountId}`,
      description: error.message,
    })
  })

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.DELETE_SERVICE_ACCOUNT, DELETE_SERVICE_ACCOUNT),
    takeEvery(actions.ADD_SERVICE_ACCOUNT, ADD_SERVICE_ACCOUNT),
    takeEvery(actions.EDIT_SERVICE_ACCOUNT, EDIT_SERVICE_ACCOUNT),
  ])
}
