/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { Breadcrumb, Card, Col, Descriptions, Badge, PageHeader, Row, Button } from 'antd'
import { TBreadcrumbItemProps } from '@antegma/accallio-cloud-react-components/lib/app/util/appRoutes'
import { TSagas } from '~/redux/interface'
import { changeSelectedProductInstanceId, getProductInstances } from '~/redux/product-instance/actions'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { getProductInstanceMembers } from '~/redux/product-instance-member/actions'
import { UserAddOutlined } from '@ant-design/icons'
import ProductInstanceMemberModal from '~/components/Modal/AddMember/ProductInstanceMemberModal'
import MemberTable from '~/components/ProductInstance/MemberTable'


const ProductDetails = (): JSX.Element => {
    const dispatch = useDispatch()
    const route = useRouteMatch<{ productId: string }>()
    const { params: { productId } } = route
    const productInstanceState = useSelector((state: TSagas) => state.productInstances);
    const selectedProductInstace = productInstanceState.productInstances.find((productInstance) => productInstance.id === productId)
    const productInstanceMemberState = useSelector((state: TSagas) => { return state.productInstanceMembers });
    const numberOfAssignedMembers = productInstanceMemberState.productInstanceMembers.length;
    const licenseQuota = selectedProductInstace?.licenseQuota || 0;
    const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);

    useEffect(() => {
        dispatch(changeSelectedProductInstanceId(productId));
    }, [])

    // Todo: Add get product instance by id
    useEffect(() => {
        dispatch(getProductInstances(1, 100));
        dispatch(getProductInstanceMembers(productInstanceMemberState.page, productInstanceMemberState.pageSize, productId, ''));
    }, [])

    return productInstanceState.loading !== true ? (
        <PageHeader title={`Product: ${selectedProductInstace?.name}`}>
            <Row gutter={16}>
                <Col span={24}>
                    <Card type="inner" title="Product details" extra={
                        <Button disabled={numberOfAssignedMembers >= licenseQuota} onClick={() => setIsMemberModalOpen(true)}>
                            <UserAddOutlined />
                            Assign User
                        </Button>
                    }>
                        <Descriptions column={1}>
                            <Descriptions.Item label="Name">{selectedProductInstace?.name}</Descriptions.Item>
                            <Descriptions.Item label="Licence Quota">{`${numberOfAssignedMembers} / ${selectedProductInstace?.licenseQuota}`}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                {selectedProductInstace?.status === 'ACTIVE' && <Badge key='green' color='green' text={selectedProductInstace?.status} />}
                                {selectedProductInstace?.status === 'TRIAL' && <Badge key='yellow' color='yellow' text={selectedProductInstace?.status} />}
                                {selectedProductInstace?.status === 'DEACTIVATED' && <Badge key='red' color='red' text={selectedProductInstace?.status} />}
                            </Descriptions.Item>
                            <Descriptions.Item label="Subscription">
                                <a href="https://antegma.onfastspring.com/account" target="_blank" rel="noopener noreferrer">
                                    Manage Subscription
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                        <MemberTable productInstanceMemberState={productInstanceMemberState} />
                    </Card>
                </Col>
            </Row>
            <ProductInstanceMemberModal isModalOpen={isMemberModalOpen} setIsModalOpen={setIsMemberModalOpen} />
        </PageHeader>
    ) : (
        <Loader />
    )
}

const LibaryElementsBreadCrumbItem: React.FunctionComponent<TBreadcrumbItemProps> = (props: TBreadcrumbItemProps) => {
    const { page, active } = props;
    const productId = page.breadcrumbProps?.lookupKey?.split('/')[2];
    const productInstanceState = useSelector((state: TSagas) => state.productInstances)
    const selectedProductInstace = productInstanceState.productInstances.find((productInstance) => productInstance.id === productId)

    const getBreadcrumbPath = (path: string): string => {
        return path.replace(':productId', selectedProductInstace?.id || '');
    }

    return active ? (
        <Breadcrumb.Item key="breadcrumb-ContentDetailView">{selectedProductInstace?.name}</Breadcrumb.Item>
    ) : (
        <Breadcrumb.Item key="breadcrumb-ContentDetailView">
            <Link to={getBreadcrumbPath(page.routeProps.path as string)}>{selectedProductInstace?.name}</Link>
        </Breadcrumb.Item>
    )
}

export default {
    name: 'Product Details',
    routeProps: {
        path: '/:productId',
        component: ProductDetails,
    },
    breadcrumbProps: {
        component: LibaryElementsBreadCrumbItem,
    },
}