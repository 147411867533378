/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import { notification } from 'antd'
import Axios from 'axios'
import { Connection } from '@antegma/accallio-cloud-connection-api'
import connectionApi from '~/services/Connections/connectionApi'
import { EConnectionType } from '~/services/Connections/types'

import actions from './actions'

/**
 *
 */
export function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const response = yield connectionApi.getConnections(undefined, undefined, 1, 50, Axios.defaults).catch((error) => {
    notification.error({
      message: 'Connections: cannot load Connections',
      description: error.message,
    })
  })

  yield put({
    type: actions.SET_STATE,
    payload: response.data,
  })
}

/**
 *
 */
export function* ADD_CONNECTION({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const { connection } = payload

  switch ((connection as Connection).type) {
    case EConnectionType.adobeAnalytics:
    case EConnectionType.adobeExperienceManager:
    case EConnectionType.marketoEngage:
    case EConnectionType.oauth2:
    case EConnectionType.adobeCampaignStandard:
      yield connectionApi.createConnection(connection, Axios.defaults)
      break

    default:
      // facebook, linkedin, twitter, instagram managed by accallio micro-service.
      break
  }

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* EDIT_CONNECTION({ payload }: AnyAction) {
  const { connection } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const connectionId = (connection as Connection).id as string

  switch ((connection as Connection).type) {
    case EConnectionType.adobeExperienceManager:
    case EConnectionType.adobeAnalytics:
    case EConnectionType.marketoEngage:
    case EConnectionType.adobeCampaignStandard:
    case EConnectionType.oauth2:
      yield connectionApi.updateConnection(connectionId, connection, Axios.defaults)
      break
    default:
      break
  }

  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* DELETE_CONNECTION({ payload }: AnyAction) {
  const { connection } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const connectionId = (connection as Connection).id as string

  yield connectionApi.deleteConnection(connectionId, Axios.defaults).catch((error) => {
    notification.error({
      message: `Connections: Unable to delete Connection with ID ${connectionId}`,
      description: error.message,
    })
  })
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.DELETE_CONNECTION, DELETE_CONNECTION),
    takeEvery(actions.ADD_CONNECTION, ADD_CONNECTION),
    takeEvery(actions.EDIT_CONNECTION, EDIT_CONNECTION),
  ])
}
