import React from 'react'
import { PageHeader, Pagination, Table, Popconfirm, Tooltip, Button } from 'antd'
import { ProductInstanceMember } from '@antegma/accallio-cloud-tenant-api'
import { DeleteOutlined, MailOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { deleteProductInstanceMember, createNewActivationCode } from '~/redux/product-instance-member/actions'


type MemberTableProps = {
    productInstanceMemberState: {
        productInstanceMembers: ProductInstanceMember[]
        page: number
        total: number
        pageSize: number
    },
}

const MemberTable = ({ productInstanceMemberState }: MemberTableProps): JSX.Element => {
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'name',
            sorter: true,
            render: (user: any): string => `${user.givenName} ${user.familyName}`,

        },
        {
            title: 'E-Mail',
            dataIndex: 'user',
            key: 'user',
            render: (user: any): string => `${user.email}`,
        },
        {
            title: 'Staus',
            dataIndex: 'status',
            key: 'status',
            render: (status: any): string => `${status}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (item: any): JSX.Element => {
                return (
                    <div>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() => dispatch(createNewActivationCode(item))}
                            title="Are you sure you want to send a new activation code to this member?"
                        >
                            <Tooltip placement="top" title="Send new Activation Code">
                                <Button type="link">
                                    <MailOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() => dispatch(deleteProductInstanceMember(item.id))}
                            title="Are you sure you want to remove this member from instance?"
                        >
                            <Tooltip placement="top" title="Remove Member">
                                <Button type="link">
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>

                )
            },
        },
    ]

    return (
        <PageHeader title="Users">
            <Table columns={columns} dataSource={productInstanceMemberState.productInstanceMembers} pagination={false} />
            <Pagination defaultCurrent={productInstanceMemberState.page} total={productInstanceMemberState.total} pageSize={productInstanceMemberState.pageSize} />
        </PageHeader>
    )
}

export default MemberTable;