/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import store from 'store'
import { AnyAction } from 'redux'
import Axios from 'axios'
import { UserList } from '@antegma/accallio-cloud-tenant-api'
import tenantApi from '~/services/tenants/tenantApi'
import actions from './actions'

/**
 *
 */
export function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  const usersResponse = yield tenantApi.getUsers(1, 50, Axios.defaults)
  const adminsResponse = yield tenantApi.getAdmins(1, 50, Axios.defaults)
  const userList: UserList = usersResponse.data
  const adminList: UserList = adminsResponse.data

  let adminIds
  if (adminList !== undefined && adminList.items !== undefined) {
    adminIds = adminList.items.map((admin) => admin.id)
  } else {
    adminIds = []
  }

  if (userList !== undefined && userList.items !== undefined) {
    const newState = {
      list: userList.items.map((user) => {
        if (adminIds.includes(user.id)) {
          return {
            ...user,
            isAdmin: true,
          }
        }
        return {
          ...user,
          isAdmin: false,
        }
      }),
    }
    yield store.set('app.tenant-users', JSON.stringify(newState))
    yield put({
      type: actions.SET_STATE,
      payload: newState,
    })
  }
}

/**
 *
 */
export function* ADD_USER({ payload }: AnyAction) {
  const { user } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  yield call(tenantApi.addUser, user.id)
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* REMOVE_USER({ payload }: AnyAction) {
  const { user } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  yield call(tenantApi.removeUser, user.id)
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* ADD_ADMIN({ payload }: AnyAction) {
  const { user } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  yield call(tenantApi.addAdmin, user.id)
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export function* REMOVE_ADMIN({ payload }: AnyAction) {
  const { user } = payload
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
  Axios.defaults.headers.Authorization = `Bearer ${accessToken}`

  yield call(tenantApi.removeAdmin, user.id)
  yield put({
    type: actions.LOAD_STATE,
  })
}

/**
 *
 */
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.ADD_USER, ADD_USER),
    takeEvery(actions.REMOVE_USER, REMOVE_USER),
    takeEvery(actions.ADD_ADMIN, ADD_ADMIN),
    takeEvery(actions.REMOVE_ADMIN, REMOVE_ADMIN),
  ])
}
