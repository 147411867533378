import { Col, Form, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { TSagas } from 'Redux/interface'
import { useDispatch, useSelector } from 'react-redux';
import { addProuctInstanceMember, getProductInstanceMembers, reset } from '~/redux/product-instance-member/actions';
import AddUserInput from './AddUserInput';


type UserAddModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
}

const ProductInstanceMemberModal = ({ isModalOpen, setIsModalOpen }: UserAddModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const productInstanceMemberState = useSelector((state: TSagas) => { return state.productInstanceMembers });
    const { selectedProductInstanceId } = useSelector((state: TSagas) => { return state.productInstances });
    const [email, setEmail] = useState<string>('');
    const [givenName, setGivenName] = useState<string>('');
    const [familyName, setFamilyName] = useState<string>('');
    const isFormValid = givenName && familyName && email;

    useEffect(() => {
        if (productInstanceMemberState.fetchStatus === 'idle')
            dispatch(getProductInstanceMembers(productInstanceMemberState.page, productInstanceMemberState.pageSize, selectedProductInstanceId, ''));
    }, [productInstanceMemberState.fetchStatus, productInstanceMemberState.page, productInstanceMemberState.pageSize]);

    const assignMember = () => {
        const productInstanceMember = {
            tenant: { id: "" },
            productInstance: { id: selectedProductInstanceId },
            user: { givenName, familyName, email },
            status: "ACTIVE",
        };
        dispatch(addProuctInstanceMember(productInstanceMember));

        setGivenName('');
        setFamilyName('');
        setEmail('');
        dispatch(reset());
        dispatch(getProductInstanceMembers(productInstanceMemberState.page, productInstanceMemberState.pageSize, selectedProductInstanceId, ''));
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            title="Assign a User and send an activation code"
            visible={isModalOpen}
            okText="Assign User"
            onOk={assignMember}
            okButtonProps={{ disabled: !isFormValid }}
            onCancel={handleCancel}
            width={800}
        >
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <AddUserInput
                            givenName={givenName}
                            setGivenName={setGivenName}
                            familyName={familyName}
                            setFamilyName={setFamilyName}
                            email={email}
                            setEmail={setEmail}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ProductInstanceMemberModal;