import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";


type AddUserInputProps = {
    givenName: string;
    setGivenName: (givenName: string) => void;
    familyName: string;
    setFamilyName: (familyName: string) => void;
    email: string;
    setEmail: (email: string) => void;
};

const AddUserInput = ({ givenName, setGivenName, familyName, setFamilyName, email, setEmail }: AddUserInputProps) => {
    const [emailValid, setEmailValid] = useState<boolean>(false);

    const validateEmail = (emaiInput: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(emaiInput);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value);
        setEmailValid(validateEmail(value));
    };

    return (
        <Row gutter={24} style={{ paddingLeft: '35px' }}>
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Given Name">
                            <Input
                                required
                                placeholder="Enter the user's given name"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Family Name">
                            <Input
                                required
                                placeholder="Enter the user's family name"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="E-Mail"
                            validateStatus={email && !emailValid ? "error" : ""}
                            help={email && !emailValid ? "Please enter a valid email address!" : ""}
                        >
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Input
                                        type="email"
                                        required
                                        placeholder="Enter the user's email address"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export default AddUserInput;