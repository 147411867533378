/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import { AnyAction } from 'redux'
import productInstanceMemberApi from '~/services/product-instances-members/productInstanceApi'
import actions from './actions'


export function* GET_PRODUCT_INSTANCE_MEMBERS({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }));

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
            fetchStatus: 'loading',
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } };
    const { page, pageSize, productInstancId, userId } = payload;

    const response = yield productInstanceMemberApi.getProductInstanceMembers(page, pageSize, productInstancId, userId, options).catch((error) => {
        notification.error({
            message: 'Products Instance: cannot load Product Instance Members',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            productInstanceMembers: response.data.items,
            page: response.data.page,
            total: response.data.total,
        },
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: true,
            fetchStatus: 'succeded',
        },
    })
}

export function* ADD_PRODUCT_INSTANCE_MEMBER({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { productInstanceMember } = payload
    yield productInstanceMemberApi.sendActivationCodeMail(productInstanceMember, options);

    yield put({
        type: actions.GET_PRODUCT_INSTANCE_MEMBERS,
        payload: {
            page: 1,
            pageSize: 10,
            tenentId: productInstanceMember.tenant.id,
            productInstancId: productInstanceMember.productInstance.id,
            userId: ''
        },
    })
}

export function* DELETE_PRODUCT_INSTANCE_MEMBER({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { id } = payload
    yield productInstanceMemberApi.deleteProductInstanceMember(id, options);

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            fetchStatus: 'idle',
        },
    })
}

export function* CREATE_NEW_ACTIVATION_CODE({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }

    const { productInstanceMember } = payload
    yield productInstanceMemberApi.deleteProductInstanceMember(productInstanceMember.id, options);
    yield productInstanceMemberApi.sendActivationCodeMail(productInstanceMember, options);

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            fetchStatus: 'idle',
        },
    })
}

/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PRODUCT_INSTANCE_MEMBERS, GET_PRODUCT_INSTANCE_MEMBERS),
        takeEvery(actions.ADD_PRODUCT_INSTANCE_MEMBER, ADD_PRODUCT_INSTANCE_MEMBER),
        takeEvery(actions.DELETE_PRODUCT_INSTANCE_MEMBER, DELETE_PRODUCT_INSTANCE_MEMBER),
        takeEvery(actions.CREATE_NEW_ACTIVATION_CODE, CREATE_NEW_ACTIVATION_CODE),
    ])
}
