import { AnyAction } from 'redux'
import { ProductInstance } from '@antegma/accallio-cloud-tenant-api'
import actions from './actions'

export type TProductInstanceState = {
    productInstances: ProductInstance[]
    selectedProductInstanceId: string
    page: number
    pageSize: number
    total: number
    loading: boolean
}

const initialState = {
    productInstances: [],
    selectedProductInstanceId: '',
    page: 1,
    pageSize: 10,
    total: 0,
    loading: false,
}

/**
 * @param state
 * @param action
 */
export default function productInstanceReducer(state: TProductInstanceState = initialState, action: AnyAction): TProductInstanceState {
    switch (action.type) {
        case actions.SET_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case actions.GET_STATE:
            return state
        case actions.CHANGE_PAGINATION:
            return {
                ...state,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
            };
        case actions.SET_SELECTED_PRODUCT_INSTANCE_ID:
            return {
                ...state,
                selectedProductInstanceId: action.payload.selectedProductInstanceId,
            };
        default:
            return state
    }
}
