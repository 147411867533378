import { EClientAuthenticationMethod, EConnectionType, EGrantType, EEventProviderType } from '~/services/Connections/types'

const de = {
  'Selected ReportingSuite': 'Ausgewählte ReportingSuite',
  None: 'Keine',
  Submit: 'Abschicken',
  'Please input a API Key!': 'Bitte einen API Key eingeben!',
  'Please input a Secret!': 'Bitte ein Secret eingeben!',
  Settings: 'Einstellungen',
  'Please input the {{value}}!': 'Bitte geben Sie einen Wert für {{value}} ein!',
  'Must be of form "<id>@techacct.adobe.com"': 'Muss der Form "<id>@techacct.adobe.com" entsprechen',
  'Must be of form "<id>@AdobeOrg"': 'Muss der Form "<id>@AdobeOrg" entsprechen',
  [EConnectionType.adobeExperienceManager]: 'Adobe Experience Manager',
  [EConnectionType.marketoEngage]: 'Marketo Engage',
  [EConnectionType.adobeAnalytics]: 'Adobe Analytics',
  [EConnectionType.microsoftAzureCognitiveServices]: 'Microsoft Azure Cognitive Services',
  [EConnectionType.facebook]: 'Facebook',
  [EConnectionType.instagram]: 'Instagram',
  [EConnectionType.twitter]: 'Twitter',
  [EConnectionType.linkedIn]: 'LinkedIn',
  [EConnectionType.adobeCampaignStandard]: 'Adobe Campaign (Standard)',
  [EGrantType.authorizationCode]: 'Authorization Code',
  [EGrantType.clientCredentials]: 'Client Credentials',
  [EGrantType.resourceOwnerCredentials]: 'Password Credentials',
  [EGrantType.implicit]: 'Implicit',
  [EClientAuthenticationMethod.form]: 'Send client credentials in body',
  [EClientAuthenticationMethod.header]: 'Send client credentials in header',
  [EClientAuthenticationMethod.query]: 'Send client credentials as query parameters',
  [EClientAuthenticationMethod.none]: 'Do not send client credentials',
  [EEventProviderType.adobeCreativeCloudWebhook]: 'Adobe Creative Cloud Webhook',
  [EEventProviderType.adobeExperienceManagerAssetsWebhook]: 'Adobe Experience Manager Assets Webhook',
  [EEventProviderType.cantoWebhookEventProvider]: 'Canto Webhook',
  [EEventProviderType.cloudEventsWebhook]: 'CloudEvents Webhook',
  [EEventProviderType.facebookPageWebhook]: 'Facebook Page Webhook',
  [EEventProviderType.instagramBusinessWebhook]: 'Instagram Business Webhook',
  [EEventProviderType.twitterWebhook]: 'Twitter Webhook',
  [EEventProviderType.whatsappBusinessWebhook]: 'Whatsapp Business Webhook',
}
export default de
