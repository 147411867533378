import store from 'store'
import { AnyAction } from 'redux'
import { User } from '@antegma/accallio-cloud-tenant-api'
import actions from './actions'

export type TTenantUsersState = { list: User[] }

const STORED_STATE = (initialState: TTenantUsersState): TTenantUsersState => {
  const storedState = store.get('app.tenant-users')
  if (storedState) {
    return JSON.parse(storedState) as TTenantUsersState
  }
  return initialState
}

const initialState = {
  ...STORED_STATE({
    list: [],
  }),
}

/**
 * @param state
 * @param action
 */
export default function tenantReducer(state: TTenantUsersState = initialState, action: AnyAction): TTenantUsersState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
