import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table } from 'antd'
import { ProductInstance } from '@antegma/accallio-cloud-tenant-api'

type ProductInstanceTableProps = {
    productInstancesState: {
        productInstances: ProductInstance[]
        page: number
        total: number
        pageSize: number
        loading: boolean
    }
}

const ProductInstanceTable = ({ productInstancesState }: ProductInstanceTableProps): JSX.Element => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (text: string, item: any): JSX.Element => (
                <Link to={`/products/${item.id}`}>
                    {text}
                </Link>
            ),
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            render: (product: any): string => `${product.name}`,
        },
    ]

    return (
        <PageHeader title="Active Products">
            <Table columns={columns} dataSource={productInstancesState.productInstances} pagination={false} loading={productInstancesState.loading} />
            <Pagination defaultCurrent={productInstancesState.page} total={productInstancesState.total} pageSize={productInstancesState.pageSize} />
        </PageHeader>
    )
}

export default ProductInstanceTable;