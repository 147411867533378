export enum EConnectionType {
  adobeAnalytics = 'AdobeAnalyticsConnection',
  adobeCampaignStandard = 'AdobeCampainStandardConnection',
  adobeExperienceManager = 'AdobeExperienceManagerConnection',
  microsoftAzureCognitiveServices = 'MicrosoftAzureCognitiveServicesConnection',
  marketoEngage = 'MarketoEngageConnection',
  facebook = 'FacebookConnection',
  instagram = 'InstagramBusinessConnection',
  canto = 'CantoConnection',
  linkedIn = 'LinkedinConnection',
  twitter = 'TwitterConnection',
  oauth2 = 'OAuth2Connection',
}

export enum EEventProviderType {
  adobeExperienceManagerAssetsWebhook = 'AdobeExperienceManagerAssetsWebhookEventProvider',
  adobeCreativeCloudWebhook = 'AdobeCreativeCloudWebhookEventProvider',
  cantoWebhookEventProvider = 'CantoWebhookEventProvider',
  cloudEventsWebhook = 'CloudEventsWebhookEventProvider',
  facebookPageWebhook = 'FacebookPageWebhookEventProvider',
  instagramBusinessWebhook = 'InstagramBusinessWebhookEventProvider',
  twitterWebhook = 'TwitterWebhookEventProvider',
  whatsappBusinessWebhook = 'WhatsappBusinessWebhookEventProvider',
}

export enum EGrantType {
  authorizationCode = 'authorization_code',
  implicit = 'implicit',
  clientCredentials = 'client_credentials',
  resourceOwnerCredentials = 'password',
}

export enum EClientAuthenticationMethod {
  none = 'none',
  header = 'header',
  query = 'query',
  form = 'form',
}

export enum EAuthenticationType {
  oauth1 = 'OAUTH1',
  oauth2 = 'OAUTH2',
  basic = 'BASIC',
  subscriptionKey = 'SUBSCRIPTION_KEY',
}
