import tenantUsers from './tenant-users/reducers'
import connections from './connections/reducers'
import eventProviders from './eventproviders/reducers'
import serviceAccounts from './serviceaccounts/reducers'
import productInstances from './product-instance/reducers'
import productInstanceMembers from './product-instance-member/reducers'

export default {
  tenantUsers,
  connections,
  eventProviders,
  serviceAccounts,
  productInstances,
  productInstanceMembers
}
