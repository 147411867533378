import { AnyAction } from 'redux'

const actions = {
    GET_PRODUCT_INSTANCES: 'PRODUCT_INSTANCE/GET_PRODUCT_INSTANCES',
    GET_PRODUCT_INSTANCE: 'PRODUCT_INSTANCE/GET_PRODUCT_INSTANCE',
    ADD_PRODUCT_INSTANCE: 'PRODUCT_INSTANCE/ADD_PRODUCT_INSTANCE',
    DELETE_PRODUCT_INSTANCE: 'PRODUCT_INSTANCE/DELETE_PRODUCT_INSTANCE',
    CHANGE_PAGINATION: 'PRODUCT_INSTANCE/CHANGE_PAGINATION',
    SET_SELECTED_PRODUCT_INSTANCE_ID: 'PRODUCT_INSTANCE/SET_SELECTED_PRODUCT_INSTANCE_ID',
    GET_STATE: 'PRODUCT_INSTANCE/GET_STATE',
    SET_STATE: 'PRODUCT_INSTANCE/SET_STATE',

}
export default actions

export const getProductInstances = (page: number, pageSize: number) => {
    return {
        type: actions.GET_PRODUCT_INSTANCES,
        payload: {
            page,
            pageSize,
        },
    }
}

export const changePagination = (page: number, pageSize: number): AnyAction => {
    return {
        type: actions.CHANGE_PAGINATION,
        payload: {
            page,
            pageSize
        },
    }
}

export const changeSelectedProductInstanceId = (selectedProductInstanceId: string): AnyAction => {
    return {
        type: actions.SET_SELECTED_PRODUCT_INSTANCE_ID,
        payload: {
            selectedProductInstanceId,
        },
    }
}
