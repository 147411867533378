import { TenantApi } from '@antegma/accallio-cloud-tenant-api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'

type TEnv = {
  TENANT_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  TENANT_API_BASE_PATH: process.env.REACT_APP_TENANT_API_BASE_PATH || 'https://tenant-api.cloud.accallio.com',
})

const tenantApi = new TenantApi(undefined, env.TENANT_API_BASE_PATH, Api.instance.axiosInstance)

// Export APIs
export default tenantApi
